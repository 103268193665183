// return var url api
// export const URL_API = 'http://127.0.0.1:8000'
export const URL_API = 'https://cathyjobedesigns.com'
// return var header whitout token
export const headersToken = token => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${token}`
    }
  }
}
export const headersTokenMultipart = token => {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${token}`
    }
  }
}
// return var header whit token
export const headers = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
}
// return var data (convert values to FormData)
export const toFormData = values => {
  const data = new FormData()

  for (var key in values) {
    if (typeof (values[key]) === 'object' && values[key] !== null && Array.isArray(values[key])) {
      data.append(key, JSON.stringify(values[key]))
    } else {
      data.append(key, values[key])
    }
  }

  return data
}
