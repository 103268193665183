<template>
  <v-app>

     <v-navigation-drawer
      app
      v-model="drawer"
      :mini-variant.sync="mini"
      fixed
      :temporary="mobile"
      v-if="authenticated"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <!-- <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img> -->
        </v-list-item-avatar>

        <v-list-item-title>{{user.name}}</v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="transparent"
    >
      <div class="d-flex align-center">
        <a href="/"><span class="banner_text">CATHY JOBE DESIGNS</span></a>
      </div>

      <v-spacer></v-spacer>
      <v-menu
        left
        color="transparent"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list color="transparent">
          <v-list-item router to="/profile">
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/contact">
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        text
        router to="/projects"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn
        text
        @click="logout()"
        v-if="authenticated"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main :class="(authenticated)?'main_authenticated':''">
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>

    <div class="text-center padding_heigh_30" v-if="$router.currentRoute.name != 'login'">
      <p class="text-center">
        <v-img
          max-width="70"
          style="margin:auto;"
          :src="url_api + '/asset/images/logo.png'"
        ></v-img>
      </p>
      <p class="title_size"><a href="/" class="footer_color"><strong>CJD</strong></a></p>
      <p class="footer_color">cathybjobe@gmail.com   Tel. +1-214-213-4558</p>
    </div>

  </v-app>
</template>

<style>
  @import './assets/styles/idiomas.css';
  @font-face {
    font-family: "gillsans";
    src: local("gillsans"),
     url(./fonts/gillsans.otf) format("truetype");
  }
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
import { URL_API } from './control'
import { isMobile } from 'mobile-device-detect'
export default {
  created () {
    if (isMobile) {
      this.mini = false
      this.mobile = true
    }
  },
  name: 'App',

  data: () => ({
    // login: false,
    drawer: true,
    url_api: URL_API,
    mini: true,
    items: [
      { title: 'Home', icon: 'mdi-home', link: '/admin/home' },
      { title: 'Profile', icon: 'mdi-face-woman-profile', link: '/admin/profile' },
      { title: 'Contact', icon: 'mdi-card-account-mail', link: '/admin/contact' },
      { title: 'Projects', icon: 'mdi-briefcase', link: '/admin/projects' }
    ],
    mobile: false
  }),
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      token: 'auth/token',
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout'
    }),
    logout () {
      this.logoutAction(this.token).then(() => {
        this.$router.push({
          name: 'login'
        })
      })
    }
  }
}
</script>
