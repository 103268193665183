import axios from 'axios'
import { headers, headersToken } from '../control'

export default {
  namespaced: true,
  state: {
    token: null,
    usuario: null,
    login: false
  },
  getters: {
    authenticated (state) {
      return state.login
    },
    token (state) {
      return state.token
    },
    user (state) {
      return state.user
    }
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    SET_USER (state, user) {
      state.user = user
    },
    SET_LOGIN (state, login) {
      state.login = login
    }
  },
  actions: {
    async logIn ({ dispatch }, data) {
      const response = await axios.post('api/login', data, headers)
      dispatch('attempt', response.data)
      return response
    },
    async attempt ({ commit }, data) {
      if (data.auth) {
        commit('SET_TOKEN', data.token)
        commit('SET_USER', data.user)
        commit('SET_LOGIN', true)
      }
    },
    logout ({ commit }, token) {
      return axios.post('api/logout', { token }, headersToken(token)).then(() => {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
        commit('SET_LOGIN', null)
      })
    }
  }
}
