import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: { title: 'Login' }
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/Home.vue'),
  //   meta: { title: 'Welcome' }
  // },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   component: () => import('../views/Profile.vue'),
  //   meta: { title: 'Profile' }
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: () => import('../views/Contact.vue'),
  //   meta: { title: 'Contact' }
  // },
  // {
  //   path: '/projects',
  //   name: 'projects',
  //   component: () => import('../views/Projects.vue'),
  //   meta: { title: 'Projects' }
  // },
  // {
  //   path: '/projects/:id',
  //   name: 'projects.proyect',
  //   component: () => import('../views/Project.vue'),
  //   meta: { title: 'Projects' }
  // },
  {
    path: '/',
    name: 'admin.home',
    component: () => import('../views/admin/Home.vue'),
    meta: { title: 'Admin - Home', requiresAuth: true }
  },
  {
    path: '/admin/home',
    name: 'admin.home',
    component: () => import('../views/admin/Home.vue'),
    meta: { title: 'Admin - Home', requiresAuth: true }
  },
  {
    path: '/admin/profile',
    name: 'admin.profile',
    component: () => import('../views/admin/Profile.vue'),
    meta: { title: 'Admin - profile', requiresAuth: true }
  },
  {
    path: '/admin/contact',
    name: 'admin.contact',
    component: () => import('../views/admin/Contact.vue'),
    meta: { title: 'Admin - Contact', requiresAuth: true }
  },
  {
    path: '/admin/projects',
    name: 'admin.projects',
    component: () => import('../views/admin/Projects.vue'),
    meta: { title: 'Admin - Projects', requiresAuth: true }
  },
  {
    path: '/admin/projects/:id',
    name: 'admin.projects.proyect',
    component: () => import('../views/admin/Project.vue'),
    meta: { title: 'Admin - Project', requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: { title: '404' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const auth = store.state.auth.login
  document.title = to.meta.title
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth) {
      next()
    } else {
      next({ name: 'login' })
    }
  } else {
    if (auth) {
      next()
    } else {
      next()
    }
  }
})

export default router
